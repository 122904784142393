import { FormBuilder, FormGroup } from '@angular/forms';

export interface DockingInterval {
  open?: string | null;
  close?: string | null;
}

export interface DockingIntervalDate {
  open?: Date | null;
  close?: Date | null;
}

const formBuilder = new FormBuilder();

export class DockingIntervalGroup extends FormGroup {
  constructor(dockingInterval?: DockingInterval) {
    super(DockingIntervalGroup.buildDockingIntervalGroup(dockingInterval).controls);
  }

  static buildDockingIntervalGroup(dockingInterval?: DockingInterval): FormGroup {
    return formBuilder.group(<{ [Name in keyof DockingInterval] }>{
      open: dockingInterval?.open ?? null,
      close: dockingInterval?.close ?? null,
    });
  }
}
