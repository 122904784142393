import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ltlccShowBreadcrumbs]',
  standalone: true,
})
export class ShowBreadcrumbsDirective implements AfterViewInit {
  @Input() ltlccShowBreadcrumbs: 'block' | 'none' = 'block';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const breadcrumbs = this.elementRef.nativeElement.closest('body')?.querySelector('ltlc-breadcrumbs');
      if (breadcrumbs) {
        this.renderer.setStyle(breadcrumbs, 'display', this.ltlccShowBreadcrumbs);
      }
    });
  }
}
