<mat-icon
  class="material-icons-outlined ltlcc-AccountRequestsSentDialog-checkIcon"
  *ngIf="messageKey !== AccountRequestsMessageKey.accountrequestWasUnsuccessful"
  >check_circle</mat-icon
>
<mat-icon
  class="material-icons-outlined ltlcc-AccountRequestsSentDialog-checkIcon"
  *ngIf="messageKey === AccountRequestsMessageKey.accountrequestWasUnsuccessful"
  >error</mat-icon
>
<h1 mat-dialog-title>
  {{ 'accountList.addAccount.' + messageKey + 'Title' | translate }}
</h1>
<div mat-dialog-content>
  <span [innerHTML]="'accountList.addAccount.' + messageKey + 'Body' | translate"></span>
</div>
<div mat-dialog-actions>
  <button color="primary" mat-button mat-dialog-close>
    {{ 'defaults.close' | translate | titlecase }}
  </button>
</div>
