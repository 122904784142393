import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormControlsBaseComponent } from '../base/directives/form-controls-base.directive';
import { ValidatorFn } from '@angular/forms';
import { Validators } from '@angular/forms';
import { COMMON_REGEX } from '../base/constants/regex.const';

@Component({
  selector: 'ltlcc-form-phone-extension',
  templateUrl: './phone-extension.component.html',
  styleUrls: ['./phone-extension.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-FormPhoneExtension' },
})
export class PhoneExtensionComponent extends FormControlsBaseComponent {
  getValidatorFunctions(): ValidatorFn[] {
    return [
      Validators.pattern(COMMON_REGEX.VALID_POSITIVE_NUMBER),
      Validators.pattern(COMMON_REGEX.NO_DECIMALS_OR_NEGATIVE),
    ];
  }
  getDefaultLabel(): string {
    return this.translate.instant('form.labels.phoneExtension');
  }
}
