import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FormControlsBaseComponent } from '../base/directives';
import { OptionSelect } from '../base/interfaces/form-controls.interface';

/*
 * This component is a wrapper around the Angular Material Autocomplete component.
 * It is designed to be used with the FormControlsBaseComponent .
 * Behaviour:
 * - If allowFreeText is true, the autocomplete control will not be cleared on blur
 * - On blur, if there is only one matching option and allowFreeText is false, the autocomplete control will be assigned to that option
 */
@UntilDestroy()
@Component({
  selector: 'ltlcc-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-AutoComplete' },
})
export class AutoCompleteComponent extends FormControlsBaseComponent implements OnInit {
  private selectedOption: OptionSelect | null;
  private _options$ = new BehaviorSubject<OptionSelect[] | null>(null);
  private _filteredOptions$ = new BehaviorSubject<OptionSelect[]>(null);
  filteredOptions$: Observable<OptionSelect[] | string> = this._filteredOptions$.asObservable();

  @Input() type = 'text';
  @Input()
  set options(options: OptionSelect[]) {
    this._options$.next(options);
  }

  @Input()
  set allowFreeText(allowFreeText: boolean) {
    this._allowFreeText = coerceBooleanProperty(allowFreeText);
  }
  get allowFreeText(): boolean {
    return this._allowFreeText;
  }
  private _allowFreeText = false;

  @Input()
  set filteredOptions(filteredOptions: OptionSelect[]) {
    if (filteredOptions) {
      this._filteredOptions$.next(filteredOptions);
    }
  }

  @Input()
  set disabled(isDisabled: boolean) {
    this.setDisabledState(coerceBooleanProperty(isDisabled));
  }
  @Output() selected = new EventEmitter<OptionSelect>();

  @Input() showLabel: boolean;

  displayFn = (value: string): string => {
    return this.findlabel(value);
  };

  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  ngOnInit(): void {
    super.ngOnInit();
    this.enableFiltering();
  }

  get autocompleteControl(): FormControl {
    return <FormControl>(this.parentControl?.control || this.control);
  }

  handleExpandMoreClick(): void {
    this.trigger?.openPanel();
  }

  findOption(value: string): OptionSelect | null {
    return this.filteredList.find((option) => option.value === value);
  }

  optionSelected(selected: MatAutocompleteSelectedEvent) {
    const option: OptionSelect = {
      value: selected.option.value,
      label: selected.option.viewValue,
    };
    this.selectedOption = option;
    this.selected.emit(option);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      if (this.autocompleteControl.enabled) {
        this.autocompleteControl.disable();
      }
    } else {
      if (this.autocompleteControl.disabled) {
        this.autocompleteControl.enable();
      }
    }
  }

  handleInputFocus(): void {
    this.selectedOption = null;
  }

  handleClosed(): void {
    if (this._filteredOptions$.value instanceof Array) {
      const foundOption = this._filteredOptions$.value.find(
        (option) => option.value === this.autocompleteControl.value
      );
      if (foundOption) {
        this.selectedOption = foundOption;
      }
    }

    if (!this.allowFreeText && !this.selectedOption && this.autocompleteControl.value) {
      this.autocompleteControl.reset();
    }
  }

  private enableFiltering(): void {
    combineLatest([
      merge(of(this.autocompleteControl.value), this.autocompleteControl.valueChanges).pipe(distinctUntilChanged()),
      this._options$,
    ])
      .pipe(
        untilDestroyed(this),
        map(([inputValue, options]) => {
          if (inputValue && Array.isArray(options)) {
            const removeOptionProps = /("value":)|("label":)|(disabled:)|(renderer:)|({)|(})|(")+/g;

            return (options ?? []).filter((option) => {
              let match = false;
              const fakeOption = { ...option };
              delete fakeOption.disabled;
              delete fakeOption.renderer;
              const optionValues = JSON.stringify(fakeOption).replace(removeOptionProps, '').split(',');
              match =
                option.sticky ||
                optionValues.some((propValue) => {
                  const _proValue = propValue.toLowerCase();
                  const _searchValue = typeof inputValue === 'string' ? inputValue.toLowerCase() : '';
                  return _proValue.includes(_searchValue);
                });
              return match;
            });
          }

          return options;
        })
      )
      .subscribe((options) => {
        this._filteredOptions$.next(options);
      });
  }

  private get filteredList(): OptionSelect[] {
    return Array.isArray(this._filteredOptions$.getValue()) ? <OptionSelect[]>this._filteredOptions$.getValue() : [];
  }

  private findlabel(value: string): string {
    const option = this.findOption(value);
    return option ? (this.showLabel ? option.label || option.displayLabel : option.displayLabel) : value;
  }
}
