export enum ActionSubTypes {
  CappingInfo = 'CAPPINGINFO',
  PartnerMasterDetails = 'PARTNERMASTERDETAILS',
  CustomerMatch = 'CUSTOMERMATCH',
  OnboardingStatus = 'ONBOARDINGSTATUS',
  MadcodeDetails = 'MADCODEDETAILS',
  ShipmentOds = 'SHIPMENTODS',
  RateQuoteDetailPDF = 'RATEQUOTEDETAILPDF',
  sfrStatus = 'SFRSTATUS',
  guestPaymentCheckInvoices = 'BILLINGGUESTPAYMENTCHECKINVOICES',
  guestPaymentCreditCardControls = 'BILLINGGUESTPAYMENTCREDITCARDCONTROLS',
  guestPaymentGetCreditCardFee = 'BILLINGGUESTPAYMENTGETCREDITCARDFEE',
  processGuestPayment = 'BILLINGGUESTPAYMENT',
  accessorialsWithRecomendations = 'ACCESSORIALSWITHRECOMMENDATION',
}
