import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ltlcc-dialog-close-button',
  template: `<button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>`,
  styles: [
    `
      :host {
        margin-left: auto;
      }
    `,
  ],
})
export class ConnectDialogCloseButtonComponent {}

@Component({
  selector: 'ltlcc-dialog-close-button',
  template: `<button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>`,
  styles: [
    `
      :host {
        margin-left: auto;
      }
    `,
  ],
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatDialogModule],
})
export class DialogCloseButtonComponent {}
