import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { AnimationsHelper } from '../../helpers/animations-helper';
import { FileHelper } from './file.helper';
import { AlertService } from '../alerts';
import { TranslateService } from '@ngx-translate/core';
@UntilDestroy()
@Component({
  selector: 'ltlcc-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-FileDrop' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [AnimationsHelper.bounce],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FileDropComponent implements OnInit {
  dragover = false;
  @Input() filesControlName: string;
  @Input() multiple = true;
  //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
  @Input() accept: string;
  @Input() maxSize: number;

  constructor(
    private mainForm: FormGroupDirective,
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  get files(): FormArray {
    return <FormArray>this.mainForm.form.get(this.filesControlName);
  }

  ngOnInit(): void {
    this.files.statusChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(() => this.cd.markForCheck());
  }

  remove(index: number) {
    this.files.removeAt(index);
  }

  handleDragOver(event: DragEvent): void {
    if (!event) return;

    // Only prevent default if it's a drag event
    if (event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.dragover = true;
  }

  handleDrop(event: DragEvent): void {
    if (!event) return;

    event.preventDefault();
    event.stopPropagation();
    this.dragover = false;

    if (event.dataTransfer?.files) {
      const files = event.dataTransfer.files;
      const validFiles = Array.from(files).filter((file) => this.validateFileSize(file));

      // Only add files if there are valid ones
      if (validFiles.length > 0) {
        // Convert valid files back to FileList-like structure
        const validFileList = new DataTransfer();
        validFiles.forEach((file) => validFileList.items.add(file));

        FileHelper.addFiles(validFileList.files, this.files, this.accept, this.multiple);
      }
    }
  }

  selectFiles(event): void {
    const files: FileList = event.target.files;

    const validFiles = Array.from(files).filter((file) => this.validateFileSize(file));

    // Convert valid files back to FileList-like structure
    const validFileList = new DataTransfer();
    validFiles.forEach((file) => validFileList.items.add(file));

    FileHelper.addFiles(validFileList.files, this.files, this.accept, this.multiple);
  }

  previewFile(file): void {
    const url = URL.createObjectURL(file);
    window.open(url);
  }

  private validateFileSize(file: File): boolean {
    if (file.size > this.maxSize) {
      this.alertService.showApiWarning({
        body: this.translate.instant('claims.documentUploader.fileSizeExceeded', {
          name: file.name,
          maxSize: this.maxSize / (1024 * 1024),
        }),
      });
      return false;
    }
    return true;
  }
}
