import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { COMMON_REGEX } from '../constants/regex.const';
import { OptionSelect } from '../interfaces/form-controls.interface';

export class FormValidatorHelper {
  static dynamicValidator(
    shouldExecuteValidator: Function,
    validatorFn: ValidatorFn = Validators.required
  ): ValidatorFn {
    //TODO: Revise implementations as it seems it does not revert validation unless updateValueAndValidity is called
    return (control: AbstractControl): ValidationErrors | null => {
      return shouldExecuteValidator() ? validatorFn(control) : null;
    };
  }

  // TODO: Will get removed with https://github.xpo.com/OneXPO/ltl-angular-ext-web/pull/863 when fully imlemented
  static addressLinesValidator(requireAddressline1 = true, requireAddressline2 = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      //TODO: Rather consider converting addressLines into a presentational component with addressLine formgroup Input
      const addressLine1 = new FormControl(
        control.value?.addressLine1,
        requireAddressline1 ? Validators.required : null
      );
      const addressLine2 = new FormControl(
        control.value?.addressLine2,
        requireAddressline2 ? Validators.required : null
      );

      const errors = { ...addressLine1.errors, ...addressLine2.errors };

      return Object.keys(errors).length ? errors : null;
    };
  }

  static autocompleteValidator(validOptions: OptionSelect[]): ValidatorFn {
    const validValues = new RegExp(validOptions.map((option) => `(${option.value}$)`).join('|'), 'i');
    return Validators.pattern(validValues);
  }

  static alphabetValidator(): ValidatorFn {
    return Validators.pattern('^[a-zA-Z ]*$');
  }

  static noBadCharacterValidator(): ValidatorFn {
    return Validators.pattern(COMMON_REGEX.BAD_CHARACTERS);
  }

  static nameFieldBadCharactersValidator(): ValidatorFn {
    return Validators.pattern(COMMON_REGEX.NAME_FIELD_BAD_CHARACTERS);
  }

  static noDecimalOrNegativeValidator(): ValidatorFn {
    return Validators.pattern(COMMON_REGEX.NO_DECIMALS_OR_NEGATIVE);
  }

  static noBadCharactersCustomerRefNbrValidator(): ValidatorFn {
    return Validators.pattern(COMMON_REGEX.BAD_CHARACTERS_CUSTOMER_REF_NBR);
  }

  static numberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const value = control.value.toString().trim();
      const isValidNumber = COMMON_REGEX.VALID_POSITIVE_NUMBER.test(value);

      return isValidNumber
        ? null
        : {
            invalidNumber: {
              value: control.value,
            },
          };
    };
  }

  static maxDecimalPlacesValidator(maxDecimals: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const value = control.value.toString();
      const decimalParts = value.split('.');

      if (decimalParts.length > 1 && decimalParts[1].length > maxDecimals) {
        return {
          maxDecimalPlaces: {
            maxDecimals,
            actual: decimalParts[1].length,
          },
        };
      }

      return null;
    };
  }
}
