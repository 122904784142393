export enum SpecialService {
  GUARANTEED = 'GUARANTEED',
  STANDARD = 'STANDARD',
  GUARANTEED_BY_NOON = 'GUARANTEED_BY_NOON',
  RAPID_REMOTE_SERVICE = 'RAPID_REMOTE_SERVICE',
  DEFERRED = 'DEFERRED',
  SPOTQUOTE = 'SPOTQUOTE',
  //DO NOT USE VOLUME PRICING  just for Condition Logic
  VOLUME_PRICING = 'VOLUME', // Rename as VOLUME_PRICING quote type is invalid for pdf,
  LINEALFEET = 'LINEALFEET',
  MABD = 'MABD',
}
