import { Injectable } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { ConfirmationDialogData } from './confirmation-dialog.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private dialogService: MatLegacyDialog) {}

  open(data: ConfirmationDialogData): Observable<boolean> {
    return this.dialogService
      .open(ConfirmationDialogComponent, {
        data,
        width: data.width || '600px',
        disableClose: data.disableClose,
      })
      .afterClosed();
  }
}
