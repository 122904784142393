import { formatInTimeZone } from 'date-fns-tz';
export class DateHelper {
  static formatDateByStateCode(stateCd: string, date: Date | string | number, format: string): string | null {
    // falling back to central time as MX state code are fullnames like "stateCd": "NUEVO LEON"
    const timezone: TIMEZONE_VALUES = STATE_TIMEZONE[stateCd] ?? TIMEZONE_VALUES.CENTRAL;

    try {
      return formatInTimeZone(new Date(date), timezone, format);
    } catch {
      return null;
    }
  }

  static isNullDate(date: string | Date): boolean {
    try {
      // Covers case where date is 01-01-001 as a null date
      return !date || !(date && new Date(date).getFullYear() > 1);
    } catch {
      return true;
    }
  }

  static convertTimeStringToDate(timeString: string): Date {
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date;
  }
}

export enum TIMEZONE_VALUES {
  PACIFIC = 'America/Los_Angeles',
  MOUNTAIN = 'America/Denver',
  CENTRAL = 'America/Chicago',
  EASTERN = 'America/New_York',
  ALASKA = 'America/Anchorage',
  HAWAII = 'Pacific/Honolulu',
  ATLANTIC = 'America/Halifax',
  NEWFOUNDLAND = 'America/St_Johns',
}

export enum STATE_TIMEZONE {
  WA = TIMEZONE_VALUES.PACIFIC,
  OR = TIMEZONE_VALUES.PACIFIC,
  CA = TIMEZONE_VALUES.PACIFIC,
  NV = TIMEZONE_VALUES.PACIFIC,
  BC = TIMEZONE_VALUES.PACIFIC,
  YT = TIMEZONE_VALUES.PACIFIC,
  MT = TIMEZONE_VALUES.MOUNTAIN,
  ID = TIMEZONE_VALUES.MOUNTAIN,
  WY = TIMEZONE_VALUES.MOUNTAIN,
  UT = TIMEZONE_VALUES.MOUNTAIN,
  CO = TIMEZONE_VALUES.MOUNTAIN,
  AZ = TIMEZONE_VALUES.MOUNTAIN,
  NM = TIMEZONE_VALUES.MOUNTAIN,
  AB = TIMEZONE_VALUES.MOUNTAIN,
  NT = TIMEZONE_VALUES.MOUNTAIN,
  ND = TIMEZONE_VALUES.CENTRAL,
  SD = TIMEZONE_VALUES.CENTRAL,
  NE = TIMEZONE_VALUES.CENTRAL,
  KS = TIMEZONE_VALUES.CENTRAL,
  OK = TIMEZONE_VALUES.CENTRAL,
  TX = TIMEZONE_VALUES.CENTRAL,
  MN = TIMEZONE_VALUES.CENTRAL,
  IA = TIMEZONE_VALUES.CENTRAL,
  MO = TIMEZONE_VALUES.CENTRAL,
  AR = TIMEZONE_VALUES.CENTRAL,
  LA = TIMEZONE_VALUES.CENTRAL,
  WI = TIMEZONE_VALUES.CENTRAL,
  IL = TIMEZONE_VALUES.CENTRAL,
  TN = TIMEZONE_VALUES.CENTRAL,
  MS = TIMEZONE_VALUES.CENTRAL,
  AL = TIMEZONE_VALUES.CENTRAL,
  MB = TIMEZONE_VALUES.CENTRAL,
  SK = TIMEZONE_VALUES.CENTRAL,
  MI = TIMEZONE_VALUES.EASTERN,
  IN = TIMEZONE_VALUES.EASTERN,
  OH = TIMEZONE_VALUES.EASTERN,
  PA = TIMEZONE_VALUES.EASTERN,
  NY = TIMEZONE_VALUES.EASTERN,
  VT = TIMEZONE_VALUES.EASTERN,
  ME = TIMEZONE_VALUES.EASTERN,
  NH = TIMEZONE_VALUES.EASTERN,
  MA = TIMEZONE_VALUES.EASTERN,
  RI = TIMEZONE_VALUES.EASTERN,
  CT = TIMEZONE_VALUES.EASTERN,
  KY = TIMEZONE_VALUES.EASTERN,
  NJ = TIMEZONE_VALUES.EASTERN,
  DE = TIMEZONE_VALUES.EASTERN,
  MD = TIMEZONE_VALUES.EASTERN,
  WV = TIMEZONE_VALUES.EASTERN,
  VA = TIMEZONE_VALUES.EASTERN,
  NC = TIMEZONE_VALUES.EASTERN,
  SC = TIMEZONE_VALUES.EASTERN,
  GA = TIMEZONE_VALUES.EASTERN,
  FL = TIMEZONE_VALUES.EASTERN,
  DC = TIMEZONE_VALUES.EASTERN,
  NU = TIMEZONE_VALUES.EASTERN,
  ON = TIMEZONE_VALUES.EASTERN,
  QC = TIMEZONE_VALUES.EASTERN,
  AK = TIMEZONE_VALUES.ALASKA,
  HI = TIMEZONE_VALUES.HAWAII,
  NB = TIMEZONE_VALUES.ATLANTIC,
  NS = TIMEZONE_VALUES.ATLANTIC,
  PE = TIMEZONE_VALUES.ATLANTIC,
  //NB = TIMEZONE_VALUES.NEWFOUNDLAND TODO: Handle New Brunswick LWP-2470
}
