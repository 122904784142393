import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { AddAccountPartnerResponse, CountryCode, InfrastructureService, UserManagementApiService } from '@ltlc/api';
import { AlertService, ErrorHandlerService, StringFormatHelper } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { LtlConnectUserService } from '../../services/user.service';
import { LoaderService } from '../loader/loader.service';
import { AccountRequestsSentDialogComponent } from './account-requests-sent-dialog/account-requests-sent-dialog.component';
import { AccountEmailTemplateParams, AddAccountData, AddAccountForm } from './add-account-modal.interface';
import { EmailTemplateService } from './email-template.service';

// TODO: Consider moving the add-account form into its own component for use outside the modal.
export enum AccountRequestsMessageKey {
  accountrequestWasSuccessful = 'accountrequestWasSuccessful',
  accountrequestWasUnsuccessful = 'accountrequestWasUnsuccessful',
  requestSentForReview = 'requestSentForReview',
}
@Component({
  selector: 'ltlcc-add-account-modal',
  templateUrl: './add-account-modal.component.html',
  styleUrls: ['./add-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AddAccountModalComponent implements OnInit {
  @HostBinding('class') classes = 'ltlcc-AddAccountModal';

  formAccount: FormGroup = this.formBuilder.group(<{ [Property in keyof AddAccountForm] }>{
    companyName: [null, Validators.required],
    addressLine1: [null, Validators.required],
    addressLine2: null,
    countryCode: [CountryCode.UNITED_STATES, Validators.required],
    stateCode: [null, Validators.required],
    cityName: [null, Validators.required],
    postalCode: [null, Validators.required],
    locationType: [null, Validators.required],
    proNumber: null,
  });

  private userProfile$ = this.connectUserService.webUser$.pipe(
    map((user) => {
      if (!user) {
        return null;
      }
      return { fromEmail: user.profile.email, fromName: `${user.profile.firstName} ${user.profile.lastName}` };
    })
  );

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatLegacyDialogRef<AddAccountModalComponent>,
    private dialog: MatLegacyDialog,
    private translate: TranslateService,
    private alertsService: AlertService,
    private infrastructureService: InfrastructureService,
    private loaderService: LoaderService,
    private errorHandlerService: ErrorHandlerService,
    private emailTemplateService: EmailTemplateService,
    private connectUserService: LtlConnectUserService,
    private userManagementApiService: UserManagementApiService,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: AddAccountData
  ) {}

  ngOnInit(): void {
    if (this.data.account) {
      this.formAccount.patchValue(this.data.account);
    }
  }

  newAccount(another: boolean): void {
    if (this.formAccount.invalid) {
      const body = this.translate.instant('accountList.addAccount.invalidForm');
      this.alertsService.showApiError({ body });
      return;
    }
    const formAccount: AddAccountForm = this.formAccount.value;

    this.loaderService
      .loadData(this.adddPartnerMasterAccount(formAccount))
      .pipe(take(1), this.errorHandlerService.snackbarOnError())
      .subscribe((response) => {
        if (response) {
          const messageKey = <AccountRequestsMessageKey>StringFormatHelper.toCamelCaseNoSpaces(response.message);
          if (another) {
            this.formAccount.reset({
              countryCode: CountryCode.UNITED_STATES,
            });
            this.formAccount.markAsUntouched();
            const title = this.translate.instant('accountList.addAccount.' + messageKey + 'Title');
            const body = this.translate.instant('accountList.addAccount.' + messageKey + 'Body');
            if (messageKey === AccountRequestsMessageKey.accountrequestWasUnsuccessful) {
              this.alertsService.showApiError({ title, body });
            } else {
              this.alertsService.showApiSuccess({ title, body });
            }
          } else {
            this.dialogRef.close(true);
            this.dialog.open(AccountRequestsSentDialogComponent, {
              data: messageKey,
            });
          }
        }
      });
  }

  //TODO: confirm if this is needed as now all user are partner master users
  private sendRequest(formAccount: AddAccountForm): Observable<boolean> {
    return this.userProfile$.pipe(
      switchMap((userProfile) => {
        const addEmailParams: AccountEmailTemplateParams = {
          companyName: formAccount.companyName,
          accountName: null,
          locationType: formAccount.locationType,
          addressLine1: formAccount.addressLine1,
          addressLine2: formAccount.addressLine2,
          city: formAccount.cityName,
          stateCode: formAccount.stateCode,
          postalCode: formAccount.postalCode,
          country: formAccount.countryCode,
          proNumber: formAccount.proNumber,
          ...userProfile,
        };

        const email = this.emailTemplateService.addAccountEmailTemplate(addEmailParams);
        return this.infrastructureService.sendEmail(email);
      })
    );
  }

  private adddPartnerMasterAccount(formAccount: AddAccountForm): Observable<AddAccountPartnerResponse> {
    return this.userProfile$.pipe(
      switchMap((userProfile) => {
        return this.userManagementApiService.addAccount({
          username: userProfile.fromEmail,
          accountRequest: {
            proNumber: formAccount.proNumber,
            accountNumber: null,
            companyName: formAccount.companyName,
            countryCode: formAccount.countryCode,
            addressLine1: formAccount.addressLine1,
            addressLine2: formAccount.addressLine2,
            cityName: formAccount.cityName,
            stateCode: formAccount.stateCode,
            postalCode: formAccount.postalCode,
            locationTypeCd: formAccount.locationType,
            comments: null,
          },
        });
      })
    );
  }
}
