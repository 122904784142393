<div mat-dialog-title class="ltlcc-AddAccountModal-header">
  {{
    (data?.isFirstAccount
      ? 'accountList.addAccount.addAccountToFinishProfile'
      : 'accountList.addAccount.addAccountRequest'
    ) | translate
  }}
  <ltlcc-dialog-close-button></ltlcc-dialog-close-button>
</div>

<form [formGroup]="formAccount" ltlccFormGroupTemplate (ngSubmit)="newAccount($event.submitter.role === 'another')">
  <mat-dialog-content>
    <p class="ltlcc-AddAccountModal-text">
      {{
        (data?.isFirstAccount ? 'accountList.addAccount.allowCustomerSpecificRates' : 'accountList.addAccount.toAccess')
          | translate
      }}
    </p>

    <h3 *ngIf="data?.isFirstAccount">
      {{ 'accountList.addAccount.reviewProvidedInfo' | translate }}
    </h3>

    <div class="ltlc-Utils-grid">
      <ltlcc-form-text
        class="ltlc-Utils-grid-spanColumn2"
        [label]="'form.labels.companyName' | translate"
        formControlName="companyName"
      ></ltlcc-form-text>

      <ltlcc-form-text
        formControlName="addressLine1"
        [label]="'form.labels.addressLine1' | translate"
      ></ltlcc-form-text>

      <ltlcc-form-text
        formControlName="addressLine2"
        [label]="'form.labels.addressLine2' | translate"
      ></ltlcc-form-text>

      <ltlcc-form-select-country
        formControlName="countryCode"
        [label]="'form.labels.country' | translate"
      ></ltlcc-form-select-country>

      <ltlcc-select-state
        formControlName="stateCode"
        [countryCode]="formAccount.get('countryCode').value"
      ></ltlcc-select-state>

      <ltlcc-form-text [label]="'form.labels.city' | translate" formControlName="cityName"></ltlcc-form-text>

      <ltlcc-postal-code
        [label]="'form.labels.postalCode' | translate"
        formControlName="postalCode"
        [countryCode]="formAccount.get('countryCode').value"
      ></ltlcc-postal-code>
    </div>

    <h3>
      {{ 'accountList.addAccount.assignAccountLocationTitle' | translate }}
    </h3>

    <p class="ltlcc-AddAccountModal-text">
      {{ 'accountList.addAccount.assignAccountLocation' | translate }}
    </p>

    <p
      class="ltlcc-AddAccountModal-text"
      [innerHTML]="'accountList.addAccount.assignAccountLocationTipHtml' | translate"
    ></p>

    <div class="ltlc-Utils-grid">
      <ltlcc-select-location-type
        [label]="'accountList.addAccount.accountLocationType' | translate"
        formControlName="locationType"
      ></ltlcc-select-location-type>

      <ltlcc-form-text [label]="'accountList.addAccount.proNumber' | translate" formControlName="proNumber">
      </ltlcc-form-text>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="submit" mat-flat-button color="primary" [disabled]="formAccount.invalid">
      {{ 'accountList.addAccount.sendRequest' | translate }}
    </button>

    <button
      *ngIf="data?.enableSendAndAddAnother"
      role="another"
      type="submit"
      color="primary"
      mat-stroked-button
      [disabled]="formAccount.invalid"
    >
      {{ 'accountList.addAccount.sendRequestAdd' | translate }}
    </button>

    <button *ngIf="data?.isFirstAccount || data?.closeText" type="button" mat-stroked-button mat-dialog-close>
      {{ data?.closeText ? data.closeText : ('accountList.addAccount.doThisLater' | translate) }}
    </button>
  </mat-dialog-actions>
</form>
