import { COMMON_REGEX } from '../modules/form-controls/base/constants/regex.const';

export class StringFormatHelper {
  static SearchStringRegex = /\-0+|-|^0+/g;

  static formatPhoneNumber(phoneNumberString: string): string {
    if (!phoneNumberString) {
      return '';
    }
    const format = this.digitize(phoneNumberString).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return format;
  }

  static parsePhoneNumberAndCountryCode(number: string): { countryCode?: string; phoneNumber?: string } {
    if (!number) {
      return {};
    }

    const numbers: string = (number.match(/\d/g) || []).join(''); // remove all non numerics
    let countryCode = '1';
    let main = numbers;

    if (numbers.length === 11) {
      // then country code is included
      countryCode = numbers[0];
      main = numbers.slice(1);
    }

    return {
      countryCode,
      phoneNumber: numbers ? main.slice(0, 3) + '-' + main.slice(3) : undefined,
    };
  }

  static removeAllWhiteSpaces(text: string): string {
    return text?.replace(COMMON_REGEX.REMOVE_WHITESPACE, '') ?? '';
  }

  static removeNewlines(text: string): string {
    return text?.replace(/[\r\n]+/g, ' ');
  }

  static digitize(text: string): string {
    return text?.replace(/\D/g, '') ?? '';
  }

  static humanizeCamelCase(value: string): string {
    const result = value?.replace(/([A-Z])/g, ' $1');
    const finalResult = `${result?.charAt(0).toUpperCase()}${result?.slice(1)}`;

    return finalResult ?? '';
  }

  static toCamelCaseNoSpaces(str: string): string {
    return str
      .replace(/\s+/g, ' ')
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
      .replace(/\s+/g, '');
  }

  static humanizeKebabCase(value: string): string {
    const words = value.split('-');
    let result = '';
    words.forEach((word, i) => {
      if (i !== 0) {
        result += ' ';
      }
      result += word.toLowerCase();
    });
    const finalResult = `${result?.charAt(0).toUpperCase()}${result?.slice(1)}`;
    return finalResult ?? '';
  }

  static setCharAt(str: string, index: number, chr: string): string {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }

  // taken from https://github.com/angular/angular/blob/main/packages/common/src/pipes/case_conversion_pipes.ts#L94
  static titleCase(str: string): string {
    return str.replace(/[0-9\p{L}]\S*/gu, (txt) => txt[0].toUpperCase() + txt.slice(1).toLowerCase());
  }

  static trimString(str: string, limit: number): string {
    return str?.length >= limit ? str.slice(0, limit) : str;
  }
}
