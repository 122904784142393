export enum QuotesQueryParam {
  ConfirmationNumber = 'confirmationNumber',
  webFormsReferenceNumber = 'webFormsReferenceNumber',
  requesterEmail = 'requesterEmail',
  accountId = 'accountId',
  exhibitionClassChanged = 'exhibitionClassChanged',
  priceId = 'priceId',
}

export enum ShipmentQueryParams {
  referenceNumber = 'referenceNumber',
}

export enum CreateBillOfLadingQueryParam {
  bolTemplateEditId = 'bolEditTemplateId',
  bolTemplateId = 'bolTemplateId',
  bolId = 'bolId',
  requesterToSign = 'requesterToSign',
  purConfirmationNumber = 'purConfirmationNumber',
  schedulePickup = 'schedulePickup',
  editBOL = 'editBOL',
}

export enum CreatePickupRequestQueryParams {
  confirmationNumber = 'confirmationNumber',
  editPickupRequestId = 'editPickupRequestId',
  duplicatePickupRequestId = 'duplicatePickupRequestId',
  rateQuoteConfirmationNumber = 'rateQuoteConfirmationNumber',
  spotQuoteNumber = 'spotQuoteNumber',
  quoteType = 'quoteType',
  hasAppointment = 'hasAppointment',
  fromPickupRequestRoute = 'fromPickupRequestRoute',
  pickupCallNbr = 'pickupCallNbr',
  originCountry = 'originCountry',
  destinationCountry = 'destinationCountry',
}

export enum ServiceCenterLocatorQueryParams {
  ServiceCenterZipCode = 'serviceCenterZipCode',
  ServiceCenterCode = 'serviceCenterCode',
}

export enum ClaimsQueryParams {
  claimId = 'claimId',
}

export enum UserRegistrationParams {
  fromClaim = 'fromClaim',
  redirectApp = 'redirectApp',
}

export enum SignInParams {
  userName = 'userName',
}

export enum AdvancedNotificationsManagerQueryParams {
  notificationId = 'notificationId',
}

export enum GuestPaymentsQueryParams {
  proNumbers = 'proNumbers',
}
