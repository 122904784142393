export const APP_ROUTE_NAMES = {
  billing: 'billing',
  newBilling: 'new-billing',
  createRateQuote: 'create-rate-quote',
  createRateQuoteDynamic: 'create-rate-quote-dynamic',
  createRateQuoteFast: 'create-rate-quote-fast',
  rateQuotePricesDynamic: 'rate-quote-prices-dynamic',
  rateQuotePrices: 'rate-quote-prices',
  createPickupRequest: 'create-pickup-request',
  createPickupRequestDynamic: 'create-pickup-request-dynamic',
  existingCustomer: 'existing-customer',
  landing: 'landing',
  signIn: 'sign-in',
  registerUser: 'register-user',
  overview: 'overview',
  publicApp: 'public-app',
  app: 'app',
  home: 'home',
  shipments: 'shipments',
  quotes: 'quotes',
  pickupRequests: 'pickup-requests',
  tools: 'tools',
  currencyExchangeCalculator: 'currency-exchange-calculator',
  densityAndClassCalculator: 'density-and-class-calculator',
  serviceCenterLocator: 'service-center-locator',
  transitTimeCalculator: 'transit-time-calculator',
  weatherAndServiceAlerts: 'weather-and-service-alerts',
  addressBook: 'address-book',
  templateManager: 'template-manager',
  userPreferences: 'user-preferences',
  userAdmin: 'user-admin',
  generalInformation: 'general-information',
  passwordReset: 'password-reset',
  shippingAccounts: 'shipping-accounts',
  commodityManager: 'commodity-manager',
  billOfLadings: 'bill-of-ladings',
  billsOfLading: 'bills-of-lading',
  billOfLading: 'bill-of-lading',
  confirmation: 'confirmation',
  delayedShipment: 'delayed-shipment',
  refundForm: 'refund-form',
  view: 'view',
  edit: 'edit',
  create: 'create',
  bolOptions: 'bol-options',
  duplicate: 'duplicate',
  reports: 'reports',
  contactUs: 'contact-us',
  notificationsManagerSettings: 'notifications-manager-settings',
  customNotificationsSettings: 'custom-notification-settings',
  notificationsAndReports: 'notifications-and-reports',
  shipmentSummary: 'shipment-summary',
  alertsManager: 'alerts-manager',
  activeCustomNotifications: 'active-custom-notifications',
  subscribedReports: 'subscribed-reports',
  forbidden: '403',
  creditApplication: 'credit-application',
  unsubscribe: 'unsubscribe',
  listBillingReports: 'list-billing-reports',
  claims: 'claims',
  claimsSuccess: 'claims-success',
  list: 'list',
  userRegistration: 'user-registration',
  promptLogin: 'prompt-login',
  webForms: 'web-forms',
  requestUnsuccessful: 'request-unsuccessful',
  status: 'status',
  trackClaim: 'claims-tracking',
  details: 'details',
  freightBillCopy: 'freight-bill-copy',
  documentFinder: 'document-finder',
  debtorChange: 'debtor-change',
  error: 'error',
  disputes: 'disputes',
  disputeSuccess: 'dispute-success',
  betaTesting: 'beta-testing',
  payInvoice: 'pay-invoice',
  pay: 'pay',
  manifestReport: 'manifest-report',
  mexico: 'mexico',
  outdatedRefundForm: 'guaranteed-refund-form',
};

export const APP_ROUTE_PATHS = {
  publicApp: {
    root: `/${APP_ROUTE_NAMES.publicApp}`,
    createRateQuoteDynamic: {
      root: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createRateQuoteDynamic}`,
      promptLogin: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createRateQuoteDynamic}/${APP_ROUTE_NAMES.promptLogin}`,
      existingCustomer: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createRateQuoteDynamic}/${APP_ROUTE_NAMES.existingCustomer}`,
      create: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createRateQuoteDynamic}/${APP_ROUTE_NAMES.create}`,
    },
    createRateQuoteFast: {
      root: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createRateQuoteFast}`,
      promptLogin: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createRateQuoteFast}/${APP_ROUTE_NAMES.promptLogin}`,
      existingCustomer: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createRateQuoteFast}/${APP_ROUTE_NAMES.existingCustomer}`,
      create: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createRateQuoteFast}/${APP_ROUTE_NAMES.create}`,
    },
    contactUs: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.contactUs}`,
    createPickupRequest: {
      root: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createPickupRequest}`,
      confirmation: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createPickupRequest}/${APP_ROUTE_NAMES.confirmation}`,
    },
    createPickupRequestDynamic: {
      root: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createPickupRequestDynamic}`,
      confirmation: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.createPickupRequestDynamic}/${APP_ROUTE_NAMES.confirmation}`,
    },
    rateQuotePricesDynamic: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.rateQuotePricesDynamic}`,
    rateQuotePrices: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.rateQuotePrices}`,
    shipments: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.shipments}`,
    passwordReset: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.passwordReset}`,
    tools: {
      root: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.tools}`,
      currencyExchangeCalculator: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.currencyExchangeCalculator}`,
      densityAndClassCalculator: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.densityAndClassCalculator}`,
      serviceCenterLocator: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.serviceCenterLocator}`,
      transitTimeCalculator: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.transitTimeCalculator}`,
      weatherAndServiceAlerts: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.weatherAndServiceAlerts}`,
      creditApplication: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.creditApplication}`,
    },
    claims: {
      promptLogin: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.claims}/${APP_ROUTE_NAMES.promptLogin}`,
      create: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.claims}/${APP_ROUTE_NAMES.create}`,
      trackClaim: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.claims}/${APP_ROUTE_NAMES.trackClaim}`,
      success: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.claims}/${APP_ROUTE_NAMES.claimsSuccess}`,
    },
    userRegistration: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.userRegistration}`,
    webForms: {
      requestUnsuccessful: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.webForms}/${APP_ROUTE_NAMES.requestUnsuccessful}`,
      status: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.webForms}/${APP_ROUTE_NAMES.status}`,
    },
    guestPayments: {
      payInvoice: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.payInvoice}`,
      pay: `/${APP_ROUTE_NAMES.publicApp}/${APP_ROUTE_NAMES.payInvoice}/${APP_ROUTE_NAMES.pay}`,
    },
  },
  app: {
    root: `/${APP_ROUTE_NAMES.app}`,
    contactUs: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.contactUs}`,
    home: {
      root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.home}`,
      pickupRequests: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.home}/${APP_ROUTE_NAMES.pickupRequests}`,
      quotes: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.home}/${APP_ROUTE_NAMES.quotes}`,
      overview: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.home}/${APP_ROUTE_NAMES.overview}`,
      shipments: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.home}/${APP_ROUTE_NAMES.shipments}`,
      billsOfLading: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.home}/${APP_ROUTE_NAMES.billsOfLading}`,
    },
    claims: {
      create: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.claims}/${APP_ROUTE_NAMES.create}`,
      list: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.claims}/${APP_ROUTE_NAMES.list}`,
      details: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.claims}/${APP_ROUTE_NAMES.details}`,
      success: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.claims}/${APP_ROUTE_NAMES.claimsSuccess}`,
    },
    tools: {
      root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}`,
      billOfLadings: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.billOfLadings}`,
      currencyExchangeCalculator: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.currencyExchangeCalculator}`,
      densityAndClassCalculator: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.densityAndClassCalculator}`,
      serviceCenterLocator: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.serviceCenterLocator}`,
      transitTimeCalculator: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.transitTimeCalculator}`,
      weatherAndServiceAlerts: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.weatherAndServiceAlerts}`,
      addressBook: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.addressBook}`,
      commodityManager: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.commodityManager}`,
      templateManager: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.templateManager}`,
      refundForm: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.refundForm}`,
      creditApplication: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.creditApplication}`,
      alertsManager: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.alertsManager}`,
      freightBillCopy: {
        root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.freightBillCopy}`,
        view: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.freightBillCopy}/${APP_ROUTE_NAMES.view}`,
      },
      debtorChange: {
        root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.debtorChange}`,
        error: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.debtorChange}/${APP_ROUTE_NAMES.error}`,
      },
      documentFinder: {
        root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.documentFinder}`,
        view: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.tools}/${APP_ROUTE_NAMES.documentFinder}/${APP_ROUTE_NAMES.view}`,
      },
    },
    notificationsAndReports: {
      root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}`,
      notificationsManagerSettings: {
        root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}/${APP_ROUTE_NAMES.notificationsManagerSettings}`,
        customNotificationsSettings: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}/${APP_ROUTE_NAMES.notificationsManagerSettings}/${APP_ROUTE_NAMES.customNotificationsSettings}`,
      },
      activeCustomNotifications: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}/${APP_ROUTE_NAMES.activeCustomNotifications}`,
      reports: {
        root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}/${APP_ROUTE_NAMES.reports}`,
        list: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}/${APP_ROUTE_NAMES.reports}/${APP_ROUTE_NAMES.list}`,
        manifestReport: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}/${APP_ROUTE_NAMES.reports}/${APP_ROUTE_NAMES.manifestReport}`,
        listBillingReport: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}/${APP_ROUTE_NAMES.reports}/${APP_ROUTE_NAMES.listBillingReports}`,
        shipmentSummary: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}/${APP_ROUTE_NAMES.reports}/${APP_ROUTE_NAMES.shipmentSummary}`,
      },
      subscribedReports: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.notificationsAndReports}/${APP_ROUTE_NAMES.subscribedReports}`,
    },
    billing: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.billing}`,
    newBilling: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.newBilling}`,
    reports: {
      root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.reports}`,
      delayedShipment: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.reports}/${APP_ROUTE_NAMES.delayedShipment}`,
      listBillingReports: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.reports}/${APP_ROUTE_NAMES.listBillingReports}`,
    },
    createRateQuote: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createRateQuote}`,
    createRateQuoteDynamic: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createRateQuoteDynamic}`,
    createRateQuoteFast: {
      root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createRateQuoteFast}`,
      create: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createRateQuoteFast}/${APP_ROUTE_NAMES.create}`,
    },
    rateQuotePricesDynamic: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.rateQuotePricesDynamic}`,
    rateQuotePrices: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.rateQuotePrices}`,
    createPickupRequest: {
      root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createPickupRequest}`,
      confirmation: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createPickupRequest}/${APP_ROUTE_NAMES.confirmation}`,
      edit: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createPickupRequest}/${APP_ROUTE_NAMES.edit}`,
      duplicate: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createPickupRequest}/${APP_ROUTE_NAMES.duplicate}`,
      mexico: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createPickupRequest}/${APP_ROUTE_NAMES.mexico}`,
    },
    createPickupRequestDynamic: {
      root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createPickupRequestDynamic}`,
      confirmation: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createPickupRequestDynamic}/${APP_ROUTE_NAMES.confirmation}`,
      edit: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createPickupRequestDynamic}/${APP_ROUTE_NAMES.edit}`,
      duplicate: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.createPickupRequestDynamic}/${APP_ROUTE_NAMES.duplicate}`,
    },
    createBOLRequest: {
      bolOptions: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.billOfLading}/${APP_ROUTE_NAMES.bolOptions}`,
      create: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.billOfLading}/${APP_ROUTE_NAMES.create}`,
      edit: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.billOfLading}/${APP_ROUTE_NAMES.edit}`,
      view: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.billOfLading}/${APP_ROUTE_NAMES.view}`,
    },
    userPreferences: {
      root: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.userPreferences}`,
      generalInformation: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.userPreferences}/${APP_ROUTE_NAMES.generalInformation}`,
      passwordReset: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.userPreferences}/${APP_ROUTE_NAMES.passwordReset}`,
      shippingAccounts: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.userPreferences}/${APP_ROUTE_NAMES.shippingAccounts}`,
      creditApplication: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.userPreferences}/${APP_ROUTE_NAMES.creditApplication}`,
      userAdmin: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.userPreferences}/${APP_ROUTE_NAMES.userAdmin}`,
      betaTesting: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.userPreferences}/${APP_ROUTE_NAMES.betaTesting}`,
    },
    disputes: {
      create: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.disputes}/${APP_ROUTE_NAMES.create}`,
      success: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.disputes}/${APP_ROUTE_NAMES.disputeSuccess}`,
    },
    webForms: {
      requestUnsuccessful: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.webForms}/${APP_ROUTE_NAMES.requestUnsuccessful}`,
      status: `/${APP_ROUTE_NAMES.app}/${APP_ROUTE_NAMES.webForms}/${APP_ROUTE_NAMES.status}`,
    },
  },
  landing: `/${APP_ROUTE_NAMES.landing}`,
  signIn: `/${APP_ROUTE_NAMES.signIn}`,
  registerUser: `/${APP_ROUTE_NAMES.registerUser}`,
  forbidden: `/${APP_ROUTE_NAMES.forbidden}`,
};
