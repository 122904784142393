<ltlcc-auto-complete
  allowFreeText
  [formControl]="addressLineSearchControl"
  [dataCy]="dataCy ? dataCy + '-addressLine1' : ''"
  [label]="fieldConfig?.fields.addressLine1?.label || ('form.labels.addressLine1' | translate)"
  [placeholder]="fieldConfig?.fields.addressLine1?.placeholder"
  [filteredOptions]="addressOptions$ | async"
  [required]="addressLine1Required"
  [showLabel]="showLabel"
></ltlcc-auto-complete>
<ltlcc-form-text
  *ngIf="addressFormGroup.controls.addressLine2"
  #addressLine2
  formControlName="addressLine2"
  [autocomplete]="
    fieldConfig?.enableAllAutoComplete || fieldConfig?.fields.addressLine2?.enableAutocomplete
      ? addressFormGroupName + ' address-line2'
      : 'off'
  "
  [id]="addressFormGroupName ? addressFormGroupName + '-addressLine2' : ''"
  [dataCy]="dataCy ? dataCy + '-addressLine2' : ''"
  [label]="fieldConfig?.fields.addressLine2?.label || ('form.labels.addressLine2' | translate)"
  [placeholder]="fieldConfig?.fields.addressLine2?.placeholder"
  [hint]="'form.labels.addressLine2Placeholder' | translate"
></ltlcc-form-text>
<div class="ltlc-Utils-grid ltlc-Utils-grid-templateColumn2">
  <ltlcc-form-text
    *ngIf="addressFormGroup.controls.cityName && !fieldConfig?.fields.cityName?.hidden"
    formControlName="cityName"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfig?.fields?.cityName?.enableAutocomplete
        ? addressFormGroupName + ' address-level2'
        : 'off'
    "
    [id]="addressFormGroupName ? addressFormGroupName + '-cityName' : ''"
    [dataCy]="dataCy ? dataCy + '-cityName' : ''"
    [label]="fieldConfig?.fields.cityName?.label || ('form.labels.cityName' | translate)"
    [placeholder]="fieldConfig?.fields.cityName?.placeholder"
  ></ltlcc-form-text>
  <ltlcc-select-state
    *ngIf="addressFormGroup.controls.stateCode && !fieldConfig?.fields.stateCode?.hidden"
    formControlName="stateCode"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfig?.fields.stateCode?.enableAutocomplete
        ? addressFormGroupName + ' address-level3'
        : 'off'
    "
    [countryCode]="countryCode"
    [id]="addressFormGroupName ? addressFormGroupName + '-stateCode' : ''"
    [dataCy]="dataCy ? dataCy + '-stateCode' : ''"
    [label]="fieldConfig?.fields.stateCode?.label || ('form.labels.stateCode' | translate)"
    [placeholder]="fieldConfig?.fields.stateCode?.placeholder"
  ></ltlcc-select-state>
</div>
<div class="ltlc-Utils-grid ltlc-Utils-grid-templateColumn2">
  <ltlcc-postal-code
    *ngIf="addressFormGroup.controls.postalCode && !fieldConfig?.fields.postalCode?.hidden"
    formControlName="postalCode"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfig?.fields.postalCode?.enableAutocomplete
        ? addressFormGroupName + ' address-level4'
        : 'off'
    "
    [countryCode]="countryCode"
    [checkPostalCodeInDb]="true"
    [id]="addressFormGroupName ? addressFormGroupName + '-postalCode' : ''"
    [dataCy]="dataCy ? dataCy + '-postalCode' : ''"
    [label]="fieldConfig?.fields.postalCode?.label || ('form.labels.postalCode' | translate)"
    [placeholder]="fieldConfig?.fields.postalCode?.placeholder"
  ></ltlcc-postal-code>
  <ltlcc-form-select-country
    *ngIf="addressFormGroup.controls.countryCode && !fieldConfig?.fields.countryCode?.hidden"
    [ngClass]="fullWidthClass"
    formControlName="countryCode"
    [autocomplete]="
      fieldConfig?.enableAllAutoComplete || fieldConfig?.fields.countryCode?.enableAutocomplete
        ? addressFormGroupName + ' country'
        : 'off'
    "
    [countries]="countries"
    [hiddenCountryCodes]="hiddenCountryCodes"
    [dataCy]="dataCy ? dataCy + '-countryCode' : ''"
    [id]="addressFormGroupName ? addressFormGroupName + '-countryCode' : ''"
    [label]="fieldConfig?.fields.countryCode?.label || ('form.labels.country' | translate)"
    [placeholder]="fieldConfig?.fields.countryCode?.placeholder"
  ></ltlcc-form-select-country>
</div>
